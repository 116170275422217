$breakpoint-horizontal-info: 500px;

.sight {
	padding-top: $m-size;

	@media (min-width: $breakpoint-horizontal-info) {
		display: flex;
		align-items: center;
	}
}

.sight__image {
	position: relative;
	display: block;
	flex: 0 0 18rem;
	width: 18rem;
	height: 18rem;
	margin: 0 auto;
	border-radius: 20rem;
	border: solid 4px $off-black;
	background-color: $gray-dark;
	background-size: cover;
	background-position: center;

	@media (min-width: $breakpoint-horizontal-info) {
		margin: 0 $s-size 0 0;
	}

	@media (min-width: $breakpoint-horizontal-info) {
		flex: 0 0 16rem;
		width: 16rem;
		height: 16rem;
	}

	@media (min-width: $breakpoint-horizontal-info + 50) {
		margin: 0 $m-size 0 0;
		flex: 0 0 18rem;
		width: 18rem;
		height: 18rem;
	}
}

.sight__content {
	flex: 1 1 0px;
	display: flex;
	justify-content: space-between;
	padding-top: 1rem;
	line-height: 1.2;

	@media (min-width: $breakpoint-horizontal-info) {
		padding-top: 2rem;
	}
}

.sight__title {
	max-width: 35rem;
	margin: 0 0 $s-size 0;
	font-size: 2.4rem;
}

.sight__icon-and-text {
	display: flex;
	align-items: center;
	margin-bottom: .7rem;
}

.sight__icon {
	flex: 0 0 auto;
	margin-right: .8rem;
}

.sight__text {
	padding-top: 3px;
	font-size: 1.7rem;
}

.sight__icon-and-text--link {
	@include word-wrap;
}

.sight__likes {
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 2.5rem;
	margin-right: 1rem;
	user-select: none;

	.likes__icon {
		width: 3.5rem;
		height: 3.5rem;
	}

	.likes__text {
		margin-top: 0.5rem;
		font-size: 1.7rem;
	}

	@media (min-width: $breakpoint-horizontal-info) {
		align-self: center;
		margin-right: 2rem;
		padding-bottom: 1rem;
	}

	@media (min-width: 650px) {
		margin-right: 5rem;

		.likes__icon {
			width: 4rem;
			height: 4rem;
		}

		.likes__text {
			font-size: 1.9rem;
		}
	}

	@media (min-width: 1000px) {
		margin-right: 8rem;
	}
}

.sight__description {
	margin-top: 1.4rem;
	padding: 1.7rem 0 2.5rem 0;
	border-top: solid 1px #2b2e31;

	@media (min-width: 1000px) {
		padding-right: 10rem;
	}

	@media (min-width: $breakpoint-mobile) {
		padding-top: 2.5rem;
		margin-top: 2.3rem;
	}

	& > p:last-of-type {
		margin-bottom: .5rem;
	}
}

.sight__description-source {
	font-size: 1.3rem;
	color: $gray-light;
}

.sight__gallery .image-gallery-content:not(.fullscreen) {
	max-width: 50rem;
	padding-bottom: $m-size / 2;

	@media (min-width: $breakpoint-mobile) {
		padding-bottom: $m-size;
	}
}

.sight__cta {
	display: flex;
	justify-content: center;

	@media (min-width: $breakpoint-mobile) {
		display: block;
	}
}

.sight__cta-btn {
	display: inline-flex;
	align-items: center;
	padding: 1.25rem;
	margin-top: 2rem;
    border-radius: $border-radius;
}

.sight__cta-icon {
	height: 1.125em;
	fill: currentColor;
    margin-top: -0.1em;
	margin-right: 1rem;
	transform: rotate(180deg);
}