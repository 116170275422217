.list-filters {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $s-size 0;
	margin-top: .2rem;
}

.list-filters__category-name {
	margin: 0 $m-size 0 0;
	font-size: 1.8rem;
	font-weight: 700;
	padding-bottom: .4rem;
}

.list-filters__modifiers {
	flex: 1 0 0px;
	display: flex;
    justify-content: flex-end;
	align-items: center;
}

.list-filters__select-wrapper {
	position: relative;
	margin-right: $s-size;
	text-align: right;
}

.list-filters__select {
	position: absolute;
    width: 100%;
    height: 100%;
	right: 0;
	top: 0;
	opacity: 0;
	letter-spacing: inherit;
	cursor: pointer;
}

.list-filters__select-value {
	display: flex;
	align-items: center;
	pointer-events: none;
	padding: .6rem 0;
	cursor: pointer;

	svg {
		margin-left: .8rem;
	}
}

.list-filters__select:focus + .list-filters__select-value {
	text-decoration: underline;
}

.list-filters__sort-order {
	display: flex;
	padding: .5rem 0;
	padding-left: $m-size - $s-size;
	cursor: pointer;
}