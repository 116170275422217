.bl-item {
	position: relative;
	background-color: $off-black;
	border-radius: $border-radius;
	color: $off-white !important;
	margin-bottom: $m-size / 2;

	@media (min-width: 675px) {
		margin-bottom: $m-size * .75;
	}
}

.bl-item__btn {
	width: 100%;
	display: flex;
	align-items: center;
	color: $off-white !important;
	border: solid 1px $off-black;
	border-radius: $border-radius;
	line-height: 1.2;
	text-align: left;
	cursor: pointer;
	padding: 1.8rem 1.6rem;
	outline: none;
	-webkit-tap-highlight-color: transparent;

	@media (min-width: $breakpoint-mobile) {
		padding: 1.8rem;
	}

	@media (min-width: $breakpoint-enable-hover) {
		&:hover,
		&:focus {
			border-color: rgba($off-white, 0.1);
		}
	}
}

.bl-item__number {
	margin: 0 $s-size 0 0;
	font-size: 2.1rem;
}

.bl-item__title {
	margin: 0 $s-size 0 .6rem;
	font-size: 1.8rem;
	@include word-wrap;
}

.bl-item__number + .bl-item__title {
	margin-left: 0;
}

.bl-item__likes {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	user-select: none;
}


.bl-item__likes .likes__icon {
	width: 2.6rem;
	height: 2.6rem;
}

.bl-item__likes .likes__text {
	margin-top: 8px;
}

// Bucket list content

.bl-item__content {
	border-top: solid 1px $separator-color;
	padding: 1.8rem 1.6rem;

	@media (min-width: $breakpoint-mobile) {
		padding: 1.8rem;
	}
}

.bl-item__description {
	margin: 0;
	@include word-wrap;
}

.bl-item__related-links {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
}

.bl-item__description + .bl-item__related-links {
	margin-top: 1rem;
}

.bl-item__related-link + .bl-item__related-link {
	margin-top: .25rem;
}