.map-marker {
	position: relative;
}

.map-marker__title {
	position: absolute;
	top: -24px;
	right: 50%;
	min-width: 8rem;
	padding: 0.3rem 0.5rem;
	border-radius: $border-radius;
	background-color: $off-black;
	color: $off-white;
	text-align: center;
	opacity: 0;
	transform: translate(50%, calc(-100% + 5px));
	transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
	pointer-events: none;
	z-index: 1;

	.map-marker.hover & {
		transform: translate(50%, calc(-100% - 4px));
		opacity: 1;
	}
}

.map-marker__btn {
	@include btn-unstyled;
	position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(-50%);
	cursor: pointer;
	outline: none !important;
}

.map-marker__icon {
	fill: $brand-primary;
}