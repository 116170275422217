html {
	font-size: 62.5%;
	background-color: $deep-black;
}

body {
	background-color: $deep-black;
	color: $off-white;
	font-size: $m-size;
	font-family: Sans-Serif;
	line-height: 1.3;
	letter-spacing: .5px;

	@media (min-width: $breakpoint-mobile) {
		line-height: 1.5;
		letter-spacing: 1.1px;
	}
}

* {
	box-sizing: border-box;
}

::after, ::before {
	box-sizing: inherit;
}

svg {
	fill: $off-white;
}

a {
	text-decoration: none;
	color: $link-color;

	@media (min-width: $breakpoint-enable-hover) {
		&:hover { color: darken($link-color, 10%); }
	}
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

h1, h2, h3, h4, h5, h6, p {
	margin-top: 0;
}