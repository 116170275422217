.header-topbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-topbar__side-item {
	flex: 0 0 3rem;
}

.header-location-selector {
	display: flex;
	align-items: center;
	font-size: 1.8rem;
	padding: $m-size 0;
	user-select: none;
}

.header-location-selector__pin-icon {
	height: 2rem;
	margin-right: .4rem;
}

.header-location-selector__text {
	padding-top: .2rem;
	font-weight: 600;
	text-transform: capitalize;
}

.header-search-icon {
	height: 2.2rem;
}