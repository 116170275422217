$highlighter-height: 1px;

.nav-tabs-wrapper {
	position: sticky;
	top: 0;
	z-index: 990;
	border-bottom: solid 1px $deep-black;
}

.nav-tabs {
	position: relative;
	display: flex;
	padding-bottom: $highlighter-height;
	background-color: $off-black;
}

.nav-tabs__tab {
	flex: 1 0 0px;
	padding: 1.6rem 1.5rem 1.5rem 1.5rem;
	color: $off-white !important;
	text-align: center;
	font-size: 1.4rem;
	line-height: 1.2;
	opacity: 0.8;
	text-transform: uppercase;
	// transition: opacity .15s ease-in-out;

	&.is-active {
		opacity: 1;
	}

	@media (min-width: $breakpoint-enable-hover) {
		&:not(.is-active):hover {
			opacity: 0.9;
		}
	}

	@media (min-width: $breakpoint-mobile) {
		padding: 1.6rem;
		font-size: 1.5rem;
	}
}

.nav-tabs__separator {
	width: 2px;
	flex: 0 0 2px;
	background-color: $deep-black;
}

.nav-tabs__hightlighter {
	position: absolute;
	bottom: 0;
	left: 0;
	width: calc(50% - 1px);
	height: $highlighter-height;
	background-color: $brand-primary;
	transition: left 0.25s ease-out;
}

.nav-tabs__tab.is-active + .nav-tabs__hightlighter {
	left: calc(50% + 1px);
}