.tooltip[data-tooltip] {
	position: relative;

	&::after {
		content: attr(data-tooltip);
		position: absolute;
		right: 50%;
		bottom: calc(100% + .5rem);
		padding: .9rem;
		background-color: rgba(0, 0, 0, .85);
		border: solid 1px $off-white;
		border-radius: $border-radius;
		border-bottom-right-radius: 0;
		font-size: 1.4rem;
		transform: rotateX(90deg);
		transform-origin: bottom;
		transition: transform .15s ease-in-out;
		z-index: 1050;
	}

	&.tooltip--active::after {
		transform: rotateX(0);
	}

	@media (min-width: $breakpoint-enable-hover) {
		&:not(.tooltip--no-hover):hover::after {
			transform: rotateX(0);
			transition-delay: 500ms;
		}
	}
}