// General

.btn-unstyled {
	background: none;
	border: none;
	padding: 0;
}

// Back Button

.back-button {
	padding-right: .5rem;
	cursor: pointer;
}

.back-button--hidden {
	opacity: 0;
}

.back-button__icon {
	transform: rotateY(180deg);
}