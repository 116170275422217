.bucket-list__info {
	padding: .5rem 0 2.5rem 0;
}

.bucket-list__info-inner {
	display: flex;
	align-items: flex-start;
	color: #d2d2d2;
}

.bucket-list__infotext {
	margin: 0 1rem 0 0;
	font-size: 1.4rem;
}