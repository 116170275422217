html {
	height: 100%;
	display: flex;
	flex-direction: column;
}

body, .site, #app {
    flex: 1 0 auto;
	display: flex;
	flex-direction: column;
}

.site-header {
	flex: none;
}

.site-main {
	flex: 1 0 auto;
}

.site-footer {
	flex: none;
}