// Colors
$deep-black: #0c0c0c;
$off-black: #202225;
$off-white: #fafafa;
$gray-light: #a9a9a9;
$gray-dark: #373737;
$brand-primary: #d06408;
$link-color: #dd6600;
$separator-color: #2b2e31;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;

// Misc
$border-radius: .4rem;

// Breakpoints
$breakpoint-mobile: 625px;
$breakpoint-enable-hover: $breakpoint-mobile;

// Mixins
@mixin word-wrap {
	word-break: break-word;
}

@mixin btn-unstyled {
	padding: 0;
	margin: 0;
	border: 0;
	background-color: transparent;
}