$link-color: #7a7a7a;

.footer {
	margin-top: $m-size;
	// box-shadow: 0px -1px 10px 1px rgba(255, 255, 255, .1);
	padding: $s-size / 2 0;
}

.footer__content {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	@media (min-width: 400px) {
		flex-direction: row;
	}
}

.footer__separator {
	display: none;
	width: 1px;
	height: 1.3rem;
	margin: .4rem 0;
	background-color: $link-color;

	@media (min-width: 400px) {
		display: block;
	}
}

.footer__link {
	padding: .4rem $m-size;
	color: $link-color;
	font-size: 1.3rem;

	&:hover {
		color: lighten($link-color, 15%);
	}
}