.content-page {
	margin: 4rem 0 3rem 0;
}

.heading-underlining {
	position: relative;
	margin-bottom: 1em;

	&::after {
		content: '';
		position: absolute;
		bottom: -0.75rem;
		left: 0;
		height: 3px;
		width: 5rem;
		background-color: $brand-primary;
	}
}