.map-filters {
	display: flex;
	align-items: center;
	padding: 1rem 0;
}

.map-filters__group {
	display: flex;
	flex-direction: column;
	margin-right: $l-size;
}

.map-filters__label {
	font-size: 1.2rem;
}

.map-filters__input {
	padding: 0.5rem 0.6rem;
    border: none;
    border-bottom: solid 1px #545556;
    background: #24262a;
	color: $off-white;
	font-size: 1.5rem;
	font-weight: 300;
	letter-spacing: 0.8px;
	transition: border-color 0.1s ease-in-out;

	&:focus {
		outline: none;
		border-color: rgba($off-white, 0.5);
	}
}

.map-filters__select-wrapper {
	@extend .list-filters__select-wrapper;
}

.map-filters__select {
	@extend .list-filters__select;
}

.map-filters__select-value {
	@extend .list-filters__select-value;
	padding: 0.3rem 0;
}