// Cateogory List

.category-list {
	display: grid;
	grid-gap: $m-size / 2;
	grid-template-columns: 1fr;
	padding: $m-size / 2 0;

	@media (min-width: 340px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 560px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (min-width: 700px) {
		grid-gap: $m-size;
		padding: $m-size 0;
	}

	@media (min-width: 1000px) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.category-list__title-wrapper {
	@extend .list-filters;
	padding-bottom: 0 !important;
}

.category-list__title {
	@extend .list-filters__category-name;
	padding: .2rem 0 0 0;
}


// Category List Item

.category-item__content {
	display: block;
	position: relative;
	padding-top: 50%;
	border-radius: $border-radius;
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-color: #373A3F;
	color: white !important;
	transition: background-size 0.45s ease-out;

	@media (min-width: 340px) {
		padding-top: 75%;
	}

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.4);
	}

	@media (min-width: $breakpoint-enable-hover) {
		&:hover {
			background-size: 110%;
		}
	}
}

.category-item__title {
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%) translateZ(0);
	padding: $m-size;
	margin: 0;
	font-size: 2.2rem;
	text-align: center;
	hyphens: manual;
	transition: transform .25s ease-out;
	text-shadow: 2px 1px 3px #000;

	@media (min-width: $breakpoint-enable-hover) {
		.category-item:hover & {
			transform: translateY(-50%) scale(1.07) translateZ(0);
		}
	}

	@media (min-width: 380px) and (max-width: 450px) {
		font-size: 2rem;
	}

	@media (min-width: 360px) and (max-width: 379px) {
		font-size: 1.9rem;
	}

	@media (min-width: 340px) and (max-width: 359px) {
		font-size: 1.8rem;
	}
}