.bucket-list__heading {
	display: flex;
	align-items: center;

}

.bucket-list__info-icon {
	height: 1em;
	margin-left: 0.5rem;
	margin-top: 0.1rem;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	@media (max-width: $breakpoint-mobile - 1) {
		outline: none !important;
	}
}