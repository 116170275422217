.banner {
	display: none;
	position: relative;
	height: calc(200px + 10vw);
	max-height: 320px;
	flex-direction: column;
	justify-content: center;
	margin: $m-size 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: $border-radius;

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, .15);
	}

	@media (min-width: 560px) {
		display: flex;
	}
}

.banner__content {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 2rem;
	align-items: center;
	padding: 0 2.5rem 0 12%;
	z-index: 1;
	color: white;
	text-shadow: 2px 1px 3px #000;
}

.banner__image {
	width: 12.5rem;
	border-radius: 12.5rem;
}

.banner__title {
	margin: 0;
	font-size: calc(2.8rem + 1vw);
	font-weight: 500;
    letter-spacing: 2.5px;
	text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
}

.banner__subtitle {
	margin: 0;
	font-size: calc(1.5rem + .6vw);
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
}