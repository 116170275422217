.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
	color: white;
	text-shadow: 2px 2px 2px #000;
}

.image-gallery-fullscreen-button:not(.active):hover::before,
.image-gallery-play-button:not(.active):hover::before,
.image-gallery-left-nav:not(.active):hover::before,
.image-gallery-right-nav:not(.active):hover::before {
	color: darken(white, 25%);
}

.image-gallery.fullscreen-modal {
	z-index: 9001;
}

.image-gallery.fullscreen-modal .image-gallery-content {
	position: absolute;
	top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.image-gallery {
	display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-gallery-content.fullscreen {
	.image-gallery-image {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
	img {
		max-width: 100vw;
		max-height: 100vh;
		width: auto;
	}
}