.container {
	max-width: 120rem;
	margin: 0 auto;
	padding-left: $m-size / 2;
	padding-right: $m-size / 2;

	@media (min-width: $breakpoint-mobile) {
		padding-left: $m-size;
		padding-right: $m-size;
	}
}

.container--mobile-fullwidth {
	@extend .container;

	@media (max-width: $breakpoint-mobile) {
		padding-left: 0;
		padding-right: 0;
	}
}