::-webkit-scrollbar-button {
	display: none;
}

::-webkit-scrollbar-thumb {
	background-color: $gray-dark;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: lighten($gray-dark, 8%);
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-track:hover {
	background-color: rgba($gray-dark, .33);
}

::-webkit-scrollbar {
	width: 6px;
}