// List

.list {
	display: grid;
	grid-gap: $m-size / 2;
	grid-template-columns: 1fr;
	padding-bottom: $m-size / 2;

	@media (min-width: 675px) {
		grid-gap: $m-size;
		grid-template-columns: repeat(2, 1fr);
		padding-bottom: $m-size;
	}

	@media (min-width: 1000px) {
		grid-template-columns: repeat(3, 1fr);
	}
}

// List Item

.list-item {
	position: relative;
	display: flex;
	align-items: center;
	background-color: $off-black;
	padding: $m-size / 4;
	border-radius: $border-radius;
	color: $off-white !important;
	border: solid 1px transparent;
	line-height: 1.2;

	@media (min-width: $breakpoint-mobile) {
		padding: $m-size / 2;
	}

	@media (min-width: $breakpoint-enable-hover) {
		&:hover {
			border-color: rgba($off-white, 0.1);
		}
	}
}

.list-item__image {
	flex: 0 0 10rem;
	display: block;
	width: 10rem;
	height: 10rem;
	margin-right: 1rem;
	background-color: $gray-dark;
	border-radius: 10rem;
	background-position: center;
	background-size: cover;

	@media (min-width: $breakpoint-mobile) {
		margin-right: $s-size;
	}
}

.list-item__content {
	flex: 1 0 0px;
	display: flex;
	flex-wrap: wrap;

	.list-item.has-date & {
		padding: 1.6rem 0;
	}
}

.list-item__title {
	flex: 0 0 100%;
	margin: 0 0 $s-size 0;
	font-size: 1.8rem;
	@include word-wrap;
}

.list-item__info {
	display: flex;
	justify-content: space-between;
	font-size: 1.8rem;
	font-weight: 300;
}

.list-item__distance {
	display: flex;
	align-items: center;
	flex: 0 0 11rem;
}

.list-item__distance-icon, {
	margin-right: .6rem;
}

.list-item__userDistance-icon {
	margin-right: .3rem;
}

.list-item__distance-text,
.list-item__userDistance-text {
	padding-top: 3px;
	margin-right: 10px;
}

.list-item .likes {
	display: flex;
	align-items: center;
	font-size: 1.8rem;
	font-weight: 300;

	.likes__text {
		padding-top: 3px;
	}
}

.list-item .likes__icon {
	height: 2rem;
	margin-right: .3rem;
}

.list-item__date {
	position: absolute;
	top: 4px;
	right: 5px;
	display: flex;
	align-items: center;
}

.list-item__date-text {
	font-size: 1.2rem;
	margin-right: 3px;
}